var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-content',[_c('page-title',{attrs:{"heading":"รายงานขอเบิกค่าตอบแทนการสอน"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"mt-3 px-3"},[_c('h4',{staticClass:"text-danger font-weight-bold"},[_vm._v("ข้อมูลครูตำรวจ")]),_c('div',[_c('h6',{staticClass:"font-weight-bold"},[_vm._v("ชื่อ-นามสกุล : "+_vm._s(_vm.name))]),_c('h6',{staticClass:"font-weight-bold"},[_vm._v("ยศ : "+_vm._s(_vm.rank))])])])]},proxy:true},{key:"right",fn:function(){return [_c('b-button',{attrs:{"variant":"danger","disabled":_vm.authUser.isExecutive},on:{"click":_vm.onCreate}},[_vm._v("เพิ่มรายงาน")])]},proxy:true}])}),_c('filter-fiscal-year-term',{staticClass:"mb-2",model:{value:(_vm.formFiscalYearTerm),callback:function ($$v) {_vm.formFiscalYearTerm=$$v},expression:"formFiscalYearTerm"}}),(_vm.authUser.isAdminType)?_c('filter-master',{attrs:{"m-headquarter-id":!_vm.authUser.canAccessAllHeadQuarters ? _vm.authUser.mHeadquarterId : null,"m-division-id":!_vm.authUser.canAccessAllDivisions ? _vm.authUser.mDivisionId : null,"m-station-id":!_vm.authUser.canAccessAllStations ? _vm.authUser.mStationId : null,"disabledInput":{
        headQuarter:
          !_vm.authUser.canAccessAllHeadQuarters &&
          _vm.authUser.mHeadquarterId !== null,
        division:
          !_vm.authUser.canAccessAllDivisions && _vm.authUser.mDivisionId !== null,
        station:
          !_vm.authUser.canAccessAllStations && _vm.authUser.mStationId !== null,
      },"col-headquarter":"3","col-division":"3","col-station":"3","col-school":"3","hidden-input":{ schoolClass: true }},model:{value:(_vm.filterMaster),callback:function ($$v) {_vm.filterMaster=$$v},expression:"filterMaster"}}):_vm._e(),_c('transection-table',{ref:"table_transection",staticClass:"mt-4",attrs:{"fields":_vm.fields,"params":_vm.params},on:{"seeDetail":_vm.onSeeDetail}}),_c('create-transection',{attrs:{"transactionId":_vm.transactionId,"isReadOnly":_vm.isReadOnly},on:{"create:success":_vm.onCreateSuccess,"hide":_vm.clearData},model:{value:(_vm.showModalTransection),callback:function ($$v) {_vm.showModalTransection=$$v},expression:"showModalTransection"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }