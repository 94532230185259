var { sortBy } = require('lodash');

// const numToWord = {
//     '1': 'หนึ่ง',
//     '2': 'สอง',
//     '3': 'สาม',
//     '4': 'สี่',
//     '5': 'ห้า',
//     '6': 'หก',
//     '7': 'เจ็ด',
//     '8': 'แปด',
//     '9': 'เก้า',
//     '0': 'ศูนย์',
// }

function formatThai(date = "") {
    const tmpDate = new Date(date)

    let monthNames = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.",
        "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.",
        "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];

    let day = tmpDate.getDate();

    let monthIndex = tmpDate.getMonth();
    let monthName = monthNames[monthIndex];

    let year = tmpDate.getFullYear();

    return `${day} ${monthName} ${year}`;
    // return tmpDate.toLocaleDateString('th-TH', {
    //     year: 'numeric',
    //     month: 'long',
    //     day: 'numeric',
    // })
}

// function formatCurrencyThai(number = 0) {
//     return new Intl.NumberFormat('th-TH').format(number);
// }

// function formatCurrencyNumberToText(number = 0) {
//     let str = number.toString().split('')
//     let wording = "";
//     if (str && str.length == 6) {
//         wording = `${numToWord[str[0]]}แสน${str[1] == '0' ? '' : numToWord[str[1]] + 'หมื่น'}${str[2] == '0' ? '' : numToWord[str[2]] + 'พัน'}${str[3] == '0' ? '' : numToWord[str[3]] + 'ร้อย'}บาทถ้วน`
//     } else if (str && str.length == 5) {
//         wording = `${numToWord[str[0]]}หมื่น${str[1] == '0' ? '' : numToWord[str[1]] + 'พัน'}${str[2] == '0' ? '' : numToWord[str[2]] + 'ร้อย'}บาทถ้วน`
//     } else if (str && str.length == 4) {
//         wording = `${numToWord[str[0]]}พัน${str[1] == '0' ? '' : numToWord[str[1]] + 'ร้อย'}บาทถ้วน`
//     } else if (str && str.length == 3) {
//         wording = `${numToWord[str[0]]}ร้อยบาทถ้วน`
//     }

//     return wording
// }

export async function generateQrCodePayment(item = {}) {
    const { title, srcImgBase64 } = item

    return `
    <!DOCTYPE html>
    <html>
    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title></title>
    </head>
    <body style="color: rgb(0, 0, 0); background-color: rgb(255, 255, 255);">
    <p style='text-align: center; margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 20px;"><strong><span style='font-family:"TH Niramit AS";'>${title ? title : ''}</span></strong></span></p>
    <br />
    <p class="pb-0 mb-0" style='text-align: center; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>ระบบจะทำการส่ง SMS ยืนยันการอนุมัติการสมัครสมาชิกชมรมแดร์ประเทศไทยให้ท่านอีกครั้ง</span></p>
    <p style='text-align: center; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>เพื่อรับหมายเลขการสมัครสมาชิกเพื่อใช้ในการอัพโหลดหลักฐานการชำระเงิน</span></p>
    <br />
    <p class="pb-0 mb-0" style='text-align: center; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>ท่านสามารถดาวน์โหลด QR Code ข้างล่างเพื่อใช้ในการสแกนตรวจสอบ</span></p>
    <p style='text-align: center; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>รายละเอียดการสมัครสมาชิกได้ในภายหลัง</span></p>
    <br />
    <p style='text-align: center; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>ขอบคุณสำหรับการสมัครสมาชิกเพื่อเป็นส่วนหนึ่งของชมรมแดร์ประเทศไทย</span></p>
    <br />
    <p class="py-3" style="text-align: center;"><img src=${srcImgBase64 ? srcImgBase64 : ''} style="width: 300px; height: 300px;"/></p>
    </body>
    
    </html>
    `
}

export async function generateQrCode(item = {}, teachingDare = {}) {
    const { surveyTitle, srcImgBase64 } = item
    const {
        m_school = {},
        m_class = {},
        room = '',
        actual_student = '',
        term = '',
        fiscal_year = '',
        director_dares = [],
        teacher_dares = [],
    } = teachingDare;

    let form_teacher_dare = ""
    if (teacher_dares && teacher_dares.length > 0) {
        for (let index = 0; index < teacher_dares.length; index++) {
            const teacher_dare = teacher_dares[index];
            form_teacher_dare += `<p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>อาจารย์ประจำชั้น / อาจารย์ผู้ควบคุม: ${teacher_dare.m_prefix ? teacher_dare.m_prefix.m_prefix_name : ''} ${teacher_dare.first_name ? teacher_dare.first_name : ''} ${teacher_dare.last_name ? teacher_dare.last_name : ''}</span></p>`
        }
    } else {
        form_teacher_dare += `<p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>อาจารย์ประจำชั้น / อาจารย์ผู้ควบคุม: ............................................... </span></p>`
    }

    let info_teaching_dare = `
                                <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>ประจำภาคเรียนที่: ${term ? term : '.....................'}/${fiscal_year ? fiscal_year : '.....................'}</span></p>
                                <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>โรงเรียน: ${m_school && m_school.m_school_name ? m_school.m_school_name : '.....................'}</span></p>
                                <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>ห้องเรียน: ${m_class && m_class.m_class_name ? `${m_class.m_class_name}/${room}` : '.....................'}</span></p>
                                <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>จำนวนนักเรียน: ${actual_student ? actual_student : '.....................'} คน</span></p>
                                ${form_teacher_dare}
                                <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>ผู้อำนวยการโรงเรียน / ผู้บริหารโรงเรียน: ${director_dares && director_dares[0] ? `${director_dares[0].m_prefix ? director_dares[0].m_prefix.m_prefix_name : ''} ${director_dares[0].first_name} ${director_dares[0].last_name}` : '...............................................'}</span></p>
                            `


    return `
    <!DOCTYPE html>
    <html>
    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title></title>
    </head>
    <body style="color: rgb(0, 0, 0); background-color: rgb(255, 255, 255);">
    <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 20px;"><strong><span style='font-family:"TH Niramit AS";'>${surveyTitle ? surveyTitle : ''}</span></strong></span></p>
    <br />
    ${info_teaching_dare}
    <p class="py-3" style="text-align: center;"><img src=${srcImgBase64 ? srcImgBase64 : ''} style="width: 300px; height: 300px;"/></p>
    </body>
    
    </html>
    `
}

export async function generateQrCodeAll(item = [], teachingDare = {}) {
    let form_teacher_dare = ""
    let form_qrcode = ""
    const {
        m_school = {},
        m_class = {},
        room = '',
        actual_student = '',
        term = '',
        fiscal_year = '',
        director_dares = [],
        teacher_dares = [],
    } = teachingDare;

    if (teacher_dares && teacher_dares.length > 0) {
        for (let index = 0; index < teacher_dares.length; index++) {
            const teacher_dare = teacher_dares[index];
            form_teacher_dare += `<p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>อาจารย์ประจำชั้น / อาจารย์ผู้ควบคุม: ${teacher_dare.m_prefix ? teacher_dare.m_prefix.m_prefix_name : ''} ${teacher_dare.first_name ? teacher_dare.first_name : ''} ${teacher_dare.last_name ? teacher_dare.last_name : ''}</span></p>`
        }
    } else {
        form_teacher_dare += `<p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>อาจารย์ประจำชั้น / อาจารย์ผู้ควบคุม: ............................................... </span></p>`
    }

    let info_teaching_dare = `
                                <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>ประจำภาคเรียนที่: ${term ? term : '.....................'}/${fiscal_year ? fiscal_year : '.....................'}</span></p>
                                <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>โรงเรียน: ${m_school && m_school.m_school_name ? m_school.m_school_name : '.....................'}</span></p>
                                <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>ห้องเรียน: ${m_class && m_class.m_class_name ? `${m_class.m_class_name}/${room}` : '.....................'}</span></p>
                                <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>จำนวนนักเรียน: ${actual_student ? actual_student : '.....................'} คน</span></p>
                                ${form_teacher_dare}
                                <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>ผู้อำนวยการโรงเรียน / ผู้บริหารโรงเรียน: ${director_dares && director_dares[0] ? `${director_dares[0].m_prefix ? director_dares[0].m_prefix.m_prefix_name : ''} ${director_dares[0].first_name} ${director_dares[0].last_name}` : '...............................................'}</span></p>
                            `

    for (let index = 0; index < item.length; index++) {
        const element = item[index];
        const { survey, dataUrl } = element
        form_qrcode += `
        <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 20px;"><strong><span style='font-family:"TH Niramit AS";'>${survey && survey.survey_title ? survey.survey_title : ''}</span></strong></span></p>
        <br />
        ${info_teaching_dare}
        <p class="py-3" style="text-align: center;"><img src=${dataUrl ? dataUrl : ''} style="width: 300px; height: 300px;"/></p>
        <div class="html2pdf__page-break"></div>
        `
    }



    return `
    <!DOCTYPE html>
    <html>

    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title></title>
    </head>

    <body style="color: rgb(0, 0, 0); background-color: rgb(255, 255, 255);">
    ${form_qrcode}
    </body>

    </html>
    `
}

export async function generateTemplate(item = {}) {
    let f_html = ""
    let f_html_student_list = ""
    if (item && item.teaching_dares && item.teaching_dares.length > 0) {
        for (let index = 0; index < item.teaching_dares.length; index++) {
            const {
                m_school = {},
                m_class = {},
                room = '',
                actual_student = '',
                teaching_plans = [],
                term = '',
                fiscal_year = '',
                director_dares = [],
                mapping_teaching_students = [],
                teacher_dares = [],
                user_profile = {}
            } = item.teaching_dares[index];

            // console.log('teaching_dares', item.teaching_dares[index]);

            // console.log(
            //     m_class,
            //     actual_student,
            //     teaching_plans);

            let form_lessons_table = ""
            let form_teaching_plan = ""
            let form_teacher_dare = ""
            let form_student_lists = ""

            if (teacher_dares && teacher_dares.length > 0) {
                for (let index = 0; index < teacher_dares.length; index++) {
                    const teacher_dare = teacher_dares[index];
                    form_teacher_dare += `<p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>อาจารย์ประจำชั้น / อาจารย์ผู้ควบคุม: ${teacher_dare.m_prefix ? teacher_dare.m_prefix.m_prefix_name : ''} ${teacher_dare.first_name ? teacher_dare.first_name : ''} ${teacher_dare.last_name ? teacher_dare.last_name : ''}</span></p>`
                }
            } else {
                form_teacher_dare += `<p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>อาจารย์ประจำชั้น / อาจารย์ผู้ควบคุม: ............................................... </span></p>`
            }

            if (mapping_teaching_students && mapping_teaching_students.length > 0) {
                for (let index = 0; index < mapping_teaching_students.length; index++) {
                    const mapping_teaching_student = mapping_teaching_students[index];
                    const { student_dare } = mapping_teaching_student;
                    form_student_lists += `
                    <tr>
                        <td style="width: 10%; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0in 5.4pt; vertical-align: top;">
                            <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>${index + 1}</span></p>
                        </td>
                        <td style="width: 45%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                            <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>  ${student_dare && student_dare.m_prefix ? student_dare.m_prefix.m_prefix_name : ''} ${student_dare ? student_dare.first_name : ''} ${student_dare ? student_dare.last_name : ''}</span></p>  
                        </td>
                        <td style="width: 45%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                            <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>${student_dare.id_card ? student_dare.id_card : ''}</span></p>        
                        </td>
                    </tr>
                    `
                }
            }


            let info_teaching_dare = `
                <p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;margin-top:6.0pt;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>ประจำภาคเรียนที่: ${term ? term : '.....................'}/${fiscal_year ? fiscal_year : '.....................'}</span></p>
                <p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>โรงเรียน: ${m_school.m_school_name ? m_school.m_school_name : '.....................'} &nbsp;</span></p>
                <p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>ห้องเรียน: ${m_class.m_class_name ? `${m_class.m_class_name}/${room}` : '.....................'}</span></p>
                <p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>จำนวนนักเรียน: ${actual_student ? actual_student : '.....................'} คน</span></p>
                ${form_teacher_dare}
                <p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>ผู้อำนวยการโรงเรียน / ผู้บริหารโรงเรียน: ${director_dares && director_dares[0] ? `${director_dares[0].m_prefix ? director_dares[0].m_prefix.m_prefix_name : ''} ${director_dares[0].first_name} ${director_dares[0].last_name}` : '...............................................'}</span></p>
            `

            let info_student_list = `
                <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;margin-top:12.0pt;text-align:center;'><span style="font-size: 18px;"><strong><span style='font-family:"TH Niramit AS";'>รายชื่อนักเรียน ชั้น ${m_class.m_class_name ? `${m_class.m_class_name}/${room}` : '.....................'}</span></strong></span></p>
                <p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>โรงเรียน: ${m_school.m_school_name ? m_school.m_school_name : '.....................'}</span></p>
                <p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>ครูประจำชั้น: ${user_profile.m_rank ? user_profile.m_rank.m_rank_name : ''} ${user_profile.first_name ? user_profile.first_name : ''} ${user_profile.last_name ? user_profile.last_name : ''}</span></p>
                <p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>รหัสประจำตัวครูตำรวจ D.A.R.E:${user_profile.id_governmen ? user_profile.id_governmen : '.....................'}</span></p>
                <p style='margin:0in;font-size:21px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>จำนวนนักเรียน: ${actual_student ? actual_student : '.....................'} คน</span></p>
            `
            let sort_teaching_plans = sortBy(teaching_plans, [function (o) { return parseInt(o.lesson_number); }])

            if (sort_teaching_plans && sort_teaching_plans.length > 0) {
                for (let index = 0; index < sort_teaching_plans.length; index++) {
                    const lesson = sort_teaching_plans[index];
                    let form_lesson_content = '';
                    let form_lesson_note = '';
                    let form_lesson_teaching_actual_imgs = `
                        <p class="pb-4" style='margin:0in;font-size:18px;font-family:"Angsana New",serif;margin-top:12.0pt;text-align:center;'><span style="font-size: 18px;"><strong><span style='font-family:"TH Niramit AS";'>ภาพกิจกรรม</span></strong></span></p>
                        <p class="pb-3" style="text-align: center;"><span style="font-size: 18px;"><strong><span style='font-family: "TH Niramit AS";'>ครั้งที่ &nbsp;${lesson.lesson_number ? lesson.lesson_number : ''} &nbsp; เรื่อง &nbsp; บทที่ ${lesson.lesson_number ? lesson.lesson_number : ''} &nbsp; ${lesson.lesson_name ? lesson.lesson_name : ''}</span></strong></span></p>
                        `;
                    let lesson_contents = lesson.content ? lesson.content.split('\n') : '';
                    let teaching_actual_imgs = lesson.teaching_actual_imgs ? lesson.teaching_actual_imgs : null;
                    if (teaching_actual_imgs && teaching_actual_imgs.length > 0) {
                        for (let index = 0; index < teaching_actual_imgs.length; index++) {
                            const teaching_actual_img = teaching_actual_imgs[index];
                            const { teaching_act } = teaching_actual_img;
                            if (teaching_act) {
                                const toDataURL = (url) =>
                                    fetch(url)
                                        .then((response) => response.blob())
                                        .then(
                                            (blob) =>
                                                new Promise((resolve, reject) => {
                                                    const reader = new FileReader();
                                                    reader.onloadend = () => resolve(reader.result);
                                                    reader.onerror = reject;
                                                    reader.readAsDataURL(blob);
                                                })
                                        );
                                let imgBase64 = await toDataURL(teaching_act);
                                if (imgBase64) {
                                    form_lesson_teaching_actual_imgs += `<p class="py-3" style="text-align: center;"><img src=${imgBase64 ? imgBase64 : ''} style="width: 480px; height: 360px;"/></p>`
                                }
                            }
                            if (teaching_actual_img.teaching_actual_img) {
                                const toDataURL = (url) =>
                                    fetch(url)
                                        .then((response) => response.blob())
                                        .then(
                                            (blob) =>
                                                new Promise((resolve, reject) => {
                                                    const reader = new FileReader();
                                                    reader.onloadend = () => resolve(reader.result);
                                                    reader.onerror = reject;
                                                    reader.readAsDataURL(blob);
                                                })
                                        );
                                let imgBase64 = await toDataURL(teaching_actual_img.teaching_actual_img);
                                if (imgBase64) {
                                    form_lesson_teaching_actual_imgs += `<p class="py-4" style="text-align: center;"><img src=${imgBase64 ? imgBase64 : ''} style="width: 480px; height: 360px;"/></p>`
                                }
                            }

                            if (teaching_actual_imgs.length == 1) {
                                form_lesson_teaching_actual_imgs += `
                                    <p class="py-5" style="text-align: center; width: 480px; height: 400px;"></p>
                                `
                            }
                        }
                    } else {
                        form_lesson_teaching_actual_imgs += `
                            <p class="py-4" style="text-align: center; width: 480px; height: 400px;"></p> 
                            <p class="py-4" style="text-align: center; width: 480px; height: 400px;"></p> 
                        `
                    }


                    for (let index = 0; index < lesson_contents.length; index++) {
                        const lesson_content = lesson_contents[index];

                        const size = 47
                        if (lesson_content.length > size) {
                            const numChunks = Math.ceil(lesson_content.length / size)
                            let chunk = ''
                            for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
                                chunk = lesson_content.substr(o, size)

                                form_lesson_content += `<p style='margin:0in;font-size:14px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 14px;' >${chunk}</p>`
                            }
                        } else {
                            form_lesson_content += `<p style='margin:0in;font-size:14px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 14px;' >${lesson_content}</p>`
                        }
                    }

                    const lesson_note = lesson.note ? lesson.note.replace(/ /g, '') : '';
                    const size = 13;
                    if (lesson_note.length > size) {
                        const numChunks = Math.ceil(lesson_note.length / size)
                        let chunk = ''
                        for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
                            chunk = lesson_note.substr(o, size)

                            form_lesson_note += `<p style='margin:0in;font-size:15px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 15px;' >${chunk}</p>`
                        }
                    } else {
                        form_lesson_note += `<p style='margin:0in;font-size:15px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 15px;' >${lesson_note}</p>`
                    }


                    form_lessons_table += `
                        <tr>
                            <td style="width: 39.15pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:15px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 15px;'>${lesson.lesson_number ? lesson.lesson_number : ''}</span></p>
                            </td>
                            <td style="width: 86.75pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:15px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 15px;'>${lesson.date ? formatThai(lesson.date) : ''}</span></p>
                            </td>
                            <td style="width: 92.15pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:15px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 15px;'>${lesson.time ? `${lesson.time} น.` : ''}</span></p>
                            </td>
                            <td style="width: 290.6pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:15px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 15px;'>บทที่ ${lesson.lesson_number ? lesson.lesson_number : ''} &nbsp;${lesson.lesson_name ? lesson.lesson_name : ''}</span></p>
                            </td>
                        </tr>
                    `

                    form_teaching_plan += `
                        <div style='height: 900.24px' class="p-0 m-0" >
                            <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 18px;"><strong><span style='font-family:"TH Niramit AS";'>บันทึกการสอน</span></strong></span></p>
                            <p class="pb-4" style='margin:0in;font-size:18px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 18px;"><strong><span style='font-family:"TH Niramit AS";'>โครงการการศึกษาเพื่อต่อต้านการใช้ยาเสพติดในเด็กนักเรียน (</span></strong><strong><span style='font-family:"TH Niramit AS";'>D.A.R.E.ประเทศไทย)</span></strong></span></p>
                            <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;margin-top:12.0pt;'><span style="font-size: 18px;"><strong><span style='font-family:"TH Niramit AS";'>ครั้งที่ &nbsp;${lesson.lesson_number ? lesson.lesson_number : ''} &nbsp; เรื่อง &nbsp; บทที่ ${lesson.lesson_number ? lesson.lesson_number : ''} &nbsp; ${lesson.lesson_name ? lesson.lesson_name : ''}</span></strong></p>
                            ${info_teaching_dare}
                            <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>&nbsp;</span></p>
                            <table style="width:480pt;border-collapse:collapse;border:none;">
                                <tbody>
                                    <tr>
                                        <td style="width: 15%; border: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                                            <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>วัน เดือน ปี</span></p>
                                        </td>
                                        <td style="width: 15%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                                            <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>เวลา</span></p>
                                        </td>
                                        <td style="width: 53%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                                            <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>เนื้อหา / และกิจกรรมที่ทำการสอน</span></p>
                                        </td>
                                        <td style="width: 17%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                                            <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>หมายเหตุ</span></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 15%; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0in 5.4pt; vertical-align: top;">
                                            <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>${lesson.date ? formatThai(lesson.date) : ''}</span></p>
                                        </td>
                                        <td style="width: 15%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                                            <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>${lesson.time ? `${lesson.time} น.` : ''}</span></p>
                                        </td>
                                        <td style="width: 53%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                                            <p style='margin:0in;font-size:20px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>บทที่ ${lesson.lesson_number ? lesson.lesson_number : ''} &nbsp; ${lesson.lesson_name ? lesson.lesson_name : ''}</span></p>
                                            ${form_lesson_content}
                                        </td>
                                        <td style="width: 17%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                                            ${form_lesson_note}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>&nbsp;</span></p>
                            <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>ความเห็นอาจารย์ประจำชั้น หรือ อาจารย์ผู้ดูแลการเรียนการสอน</span></p>
                            <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;margin-top:6.0pt;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>...................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................</span></p>
                            <p style='margin: 12pt 0in 0in; font-size: 18px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>ลงชื่อ........................................................................อาจารย์ผู้ดูแลการเรียนการสอน</span></p>
                            <p style='margin: 0in; font-size: 18px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>&nbsp;</span></p>
                            <p style='margin: 0in; font-size: 18px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>ลงชื่อ..................................................................ผู้ดำเนินโครงการ</span></p>
                            <p style='margin: 0in; font-size: 18px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>(.............................................)</span></p>
                            <p style='margin: 0in; font-size: 18px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>.........................................................................</span></p>   
                        </div>
                        ${form_lesson_teaching_actual_imgs}
                        <br />
                    `
                }
            }

            f_html_student_list += `
            ${info_student_list}
            <br />
            <table style="width:480pt;border-collapse:collapse;border:none;">
                <tbody>
                <tr>
                    <td style="width: 10%; border: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                        <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>ลำดับ</span></p>
                    </td>
                    <td style="width: 45%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                        <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>ชื่อ นามสกุล นักเรียน</span></p>
                    </td>
                    <td style="width: 45%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                        <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 14px;'>เลขประจำตัวประชาชน</span></p>
                    </td>
                </tr>
                    ${form_student_lists}
                </tbody>
            </table>
            <br />
            <br />
            <p style='margin: 0in; font-size: 18px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>ตรวจแล้วถูกต้อง</span></p>
            <br />
            <br />
            <p style='margin: 0in; font-size: 18px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>ลงชื่อ..................................................................</span></p>
            <p style='margin: 0in; font-size: 18px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>( ${user_profile.m_rank ? user_profile.m_rank.m_rank_name : ''} ${user_profile.first_name ? user_profile.first_name : '.........................'} ${user_profile.last_name ? user_profile.last_name : '.........................'} )</span></p>
            <p style='margin: 0in; font-size: 18px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>ตำแหน่ง ${user_profile.position_name ? user_profile.position_name : '.................................'}</span></p>
            `


            f_html += `
                <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;'><strong><span style='font-size: 18px; font-family: "TH Niramit AS";'>แผนการสอน โครงการการศึกษาเพื่อต่อต้านการใช้ยาเสพติดในเด็กนักเรียน (</span></strong><span style="font-size: 18px;"><strong><span style='font-family: "TH Niramit AS";'>D.A.R.E.ประเทศไทย)</span></strong></span></p>
                <br />
                ${info_teaching_dare}
                <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;'><span style='font-size: 18px; font-family: "TH Niramit AS";'>&nbsp;</span></p>
                <table style="width:480pt;border-collapse:collapse;border:none;">
                    <tbody>
                        <tr>
                            <td style="width: 39.15pt;border: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 14px;"><strong><span style='font-family: "TH Niramit AS";'>ลำดับ</span></strong></span></p>
                            </td>
                            <td style="width: 86.75pt;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 14px;"><strong><span style='font-family:"TH Niramit AS";'>วัน เดือน ปี</span></strong></span></p>
                            </td>
                            <td style="width: 92.15pt;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 14px;"><strong><span style='font-family:"TH Niramit AS";'>เวลา</span></strong></span></p>
                            </td>
                            <td style="width: 290.6pt;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 14px;"><strong><span style='font-family:"TH Niramit AS";'>เนื้อหาที่ทำการสอน</span></strong></span></p>
                            </td>
                        </tr>
                        ${form_lessons_table}
                    </tbody>
                </table>
                <p style='margin: 0in; font-size: 18px; font-family: "Angsana New", serif; text-align: left;'><br></p>
                <p id="isPasted" style='margin: 0in; font-size: 18px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>ลงชื่อ...........................................................................ผู้ดำเนินโครงการ</span></p>
                <p style='margin: 0in; font-size: 18px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>(..............................................)</span></p>
                <p style='margin: 0in; font-size: 18px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>........................................................................................</span></p>
                <p style='margin: 0in; font-size: 18px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>ลงชื่อ.................................................................ผู้บังคับบัญชาต้นสังกัด</span></p>
                <p style='margin: 0in; font-size: 18px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>(............................................)</span></p>
                <p class="pb-0 mb-0" style='margin: 0in; font-size: 18px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 18px;'>........................................................................................</span></p>
                <br />
                <br />
                <br />
                <br />
                <br />
                ${form_teaching_plan}
                ${f_html_student_list}
                ${index > 0 && index < item.teaching_dares.length ? `<div class="html2pdf__page-break"></div>` : ''}
            `
        }
    }
    // let schools = "";
    // let lesson_plan_counts = 0;
    // let teaching_dare_table = "";
    // let total_time_lesson_plan = 0;
    // let total_price_lesson_plan = 0;
    // let pricePerHrs = 0
    // if (selected == "0") {
    //     pricePerHrs = 300;
    // }
    // if (selected == "1") {
    //     pricePerHrs = 600;
    // }
    // if (item && item.teaching_dares) {
    //     for (let index = 0; index < item.teaching_dares.length; index++) {
    //         const {
    //             m_school = {},
    //             m_class = {},
    //             actual_student,
    //             teaching_plans,
    //             term,
    //             fiscal_year
    //         } = item.teaching_dares[index];

    //         schools += `<p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-indent:.5in;'><span style='font-family:"TH Niramit AS";'>โรงเรียน ${m_school?.m_school_name ? m_school.m_school_name : ""
    //             } ชั้น ${m_class?.m_class_name ? m_class.m_class_name : "..."
    //             } </span></p>
    //               <p class="mb-3" style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-indent:.5in;'><span style='font-family:"TH Niramit AS";'>จำนวนนักเรียน ${actual_student ? actual_student : 0
    //             } คน</span></p>`;

    //         lesson_plan_counts += teaching_plans.length;

    //         let hours = 0;
    //         let mins = 0;

    //         if (teaching_plans) {
    //             for (let index = 0; index < teaching_plans.length; index++) {
    //                 const element = teaching_plans[index].totalTime;
    //                 if (element) {
    //                     const times = element.split(":");
    //                     hours += parseInt(times[0]);
    //                     mins += parseInt(times[1]);
    //                 }
    //             }
    //         }

    //         total_time_lesson_plan = hours + mins / 60;
    //         total_price_lesson_plan += total_time_lesson_plan * pricePerHrs

    //         teaching_dare_table += `
    //             <tr>
    //                 <td style="width: 83%; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0in 5.4pt; vertical-align: bottom;">
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp;ค่าตอบแทนวิทยากรตามโครงการการศึกษาเพื่อต่อต้าน</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>การใช้ยาเสพติดในเด็กนักเรียน (โครงการ&nbsp;</span><span style='font-family:"TH Niramit AS";'>D.A.R.E. ประเทศไทย) ประจำภาคเรียนที่ ${term ? term : ''}/${fiscal_year ? fiscal_year : ''}</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>ณ โรงเรียน ${m_school?.m_school_name ? m_school.m_school_name : ""}</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>ตำบล .............................&nbspอำเภอ ${m_school?.m_amphur?.m_amphur_name ? m_school.m_amphur.m_amphur_name : ""}</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>จังหวัด ${m_school?.m_province?.m_province_name ? m_school.m_province.m_province_name : ""}</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>ดำเนินการระหว่างวันที่ ${teaching_plans[0] ? formatThai(teaching_plans[0].date) : ""}</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>ถึงวันที่ ${teaching_plans[teaching_plans.length - 1] ? formatThai(teaching_plans[teaching_plans.length - 1].date) : ""}</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>จำนวน ${parseInt(total_time_lesson_plan)} ชั่วโมง ๆ ละ ${pricePerHrs} บาท ต่อห้อง รวม 1 ห้องเรียน&nbsp;</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>เป็นเงิน</span></p>
    //                 </td>

    //                 <td style="width: 17%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: bottom;">
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>${formatCurrencyThai(parseInt(total_time_lesson_plan) * pricePerHrs)}</span><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
    //                 </td>
    //                 </tr>
    //         `
    //     }
    // }

    return `<!DOCTYPE html>
    <html>

    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title></title>
    </head>

    <body style="color: rgb(0, 0, 0); background-color: rgb(255, 255, 255);">
        ${f_html}
    </body>

    </html>`

    // return `
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><strong><span style='font-size:24px;font-family:"TH Niramit AS";'>เอกสารประกอบการรายงานเพื่อขอเบิกค่าตอบแทนการสอน</span></strong></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><strong><span style='font-family:"TH Niramit AS";'>โครงการการศึกษาเพื่อต่อต้านการใช้ยาเสพติดในเด็กนักเรียน (&nbsp;</span></strong><strong><span style='font-family:"TH Niramit AS";'>D.A.R.E.ประเทศไทย )</span></strong></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><strong><span style='font-family:"TH Niramit AS";'>( ภาคเรียนที่ .........................)</span></strong></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><strong><span style='font-family:"TH Niramit AS";'>&nbsp;</span></strong></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><strong><span style='font-family:"TH Niramit AS";'>ชื่อครูตำรวจ&nbsp;</span></strong><strong><span style='font-family:"TH Niramit AS";'>D.A.R.E.&nbsp;</span></strong></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ชื่อ ${user?.first_name ? user.first_name : ""} ${user?.last_name ? user.last_name : ""} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ชั้นยศ ${user?.m_rank?.m_rank_name ? user.m_rank.m_rank_name : "..."} </span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; รหัสครูตำรวจ D.A.R.E&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;..</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><strong><span style='font-family:"TH Niramit AS";'>ขอเบิกค่าตอบแทนการสอนจำนวน ${item?.teaching_dares ? item?.teaching_dares.length : 0} ห้องเรียน ดังนี้</span></strong></p>
    //     ${schools}
    //     <br>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><strong><span style='font-family:"TH Niramit AS";'>เอกสารประกอบการเบิกค่าตอบแทนการสอน</span></strong></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>1. ใบสำคัญรับเงิน&nbsp;</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>2. แผนการสอน ${lesson_plan_counts} &nbsp;บทเรียน (${item?.teaching_dares ? item?.teaching_dares.length : 0} ห้องเรียน)&nbsp;</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>3. บันทึกการสอน ${lesson_plan_counts} บทเรียน &nbsp;พร้อมภาพกิจกรรมแต่ละบทเรียน (${item?.teaching_dares ? item?.teaching_dares.length : 0} ห้องเรียน)&nbsp;</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>4. รายชื่อนักเรียนที่สอน (พร้อมเลข 13 หลัก และรหัสนักเรียน&nbsp;D.A.R.E.)</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>5. สำเนาบัตรประจำตัวประชาชน</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>6. สำเนาหน้าสมุดบัญชีครูตำรวจ&nbsp;D.A.R.E. (ธนาคารกรุงไทย) (${item?.teaching_dares ? item?.teaching_dares.length : 0} ห้องเรียน)</span></p>
    //     <p><span style='page-break-after: always;font-size:21px;font-family:"TH Niramit AS";'>7. แบบรายงานการบันทึกการสอนในระบบฐานข้อมูล&nbsp;D.A.R.E.</span></p>

    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><strong><span style='font-size:24px;font-family:"TH Niramit AS";'>ใบสำคัญรับเงิน</span></strong></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>วันที่...............เดือน.............พ.ศ..............</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><br></p>
    //     <p id="isPasted" style='margin:0in;font-size:21px;font-family:"Sarabun",serif;margin-left:.5in;text-indent:.5in;'><span style='font-family:"TH Niramit AS";'>ข้าพเจ้า...............................................ชั้นยศ..........................................</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-size:21px;font-family:"TH Niramit AS";'>อยู่บ้านเลขที่..............หมู่ที่..................ถนน..........................ตำบล..............................</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-size:21px;font-family:"TH Niramit AS";'>อำเภอ....................................................จังหวัด...........................................................</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-size:21px;font-family:"TH Niramit AS";'>ได้รับเงินจาก............................ตำรวจภูธรจังหวัด..............................................</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-size:21px;font-family:"TH Niramit AS";'>ดังรายการต่อไปนี้</span></p>

    //     <p><br></p>
    //     <table style="border-collapse:collapse;border:none;">
    //         <tbody>
    //             <tr>
    //                 <td style="width: 83%; border: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><strong><span style='font-family:"TH Niramit AS";'>รายการ</span></strong></p>
    //                 </td>
    //                 <td style="width: 17%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><strong><span style='font-family:"TH Niramit AS";'>จำนวนเงิน</span></strong></p>
    //                 </td>
    //             </tr>

    //                 ${teaching_dare_table}

    //             <tr>
    //                 <td style="border: none; padding: 0in; width: 83%;">
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'>&nbsp;</p>
    //                 </td>
    //                 <td style="width: 17%; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0in 5.4pt; height: 20.05pt; vertical-align: top;">
    //                     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>${formatCurrencyThai(total_price_lesson_plan)}</span></p>
    //                 </td>
    //             </tr>
    //         </tbody>
    //     </table>

    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  จำนวนเงิน &nbsp; (${formatCurrencyNumberToText(total_price_lesson_plan)})</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
    //     <p style='margin: 0in; font-size: 21px; font-family: "Sarabun", serif; text-align: right;'><span style='font-family:"TH Niramit AS";'>&nbsp; ลงชื่อ..................</span><span style='font-family:"TH Niramit AS";'>&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;.ผู้รับเงิน</span></p>
    //     <p id="isPasted" style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;( .................................................)</span></p>
    //     <p id="isPasted" style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp;ชั้นยศ .........................................</span></p>
    //     <p style='margin: 0in; font-size: 21px; font-family: "Sarabun", serif; text-align: right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span><span style='font-family:"TH Niramit AS";'>ลงชื่อ<span id="isPasted" style='color: rgb(0, 0, 0); font-size: 21px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: right; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; font-family: "TH Niramit AS";'>..................</span><span style='color: rgb(0, 0, 0); font-size: 21px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: right; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; font-family: "TH Niramit AS";'>&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;...</span></span><span style='font-family:"TH Niramit AS";'>ผู้จ่ายเงิน</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;( .................................................)</span></p>
    //     <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp;ชั้นยศ .........................................</span></p>
    // `;
}

export async function generateTemplate_v2(item = {}) {
    let f_html = ""
    let f_html_student_list = ""
    if (item && item.teaching_dares && item.teaching_dares.length > 0) {
        for (let index = 0; index < item.teaching_dares.length; index++) {
            const {
                m_school = {},
                m_class = {},
                room = '',
                actual_student = '',
                teaching_plans = [],
                term = '',
                fiscal_year = '',
                director_dares = [],
                mapping_teaching_students = [],
                teacher_dares = [],
                user_profile = {}
            } = item.teaching_dares[index];

            let form_lessons_table = ""
            let form_teaching_plan = ""
            let form_teacher_dare = ""
            let signature_teacher_dare = ""
            let form_student_lists = ""

            if (teacher_dares && teacher_dares.length > 0) {
                for (let index = 0; index < teacher_dares.length; index++) {
                    const teacher_dare = teacher_dares[index];
                    form_teacher_dare += `<p style='font-weight: 700; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>อาจารย์ประจำชั้น / อาจารย์ผู้ควบคุม: ${teacher_dare.m_prefix ? teacher_dare.m_prefix.m_prefix_name : ''} ${teacher_dare.first_name ? teacher_dare.first_name : ''} ${teacher_dare.last_name ? teacher_dare.last_name : ''}</span></p>`

                    signature_teacher_dare += `
                        <p class="font-weight: 700; pb-0 mb-0" style='margin: 0in; font-size: 16px; font-family: "Angsana New", serif; text-align: center;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>ลงชื่อ...........................................................................อาจารย์ประจำชั้น</span></p>
                        <p class="font-weight: 700; pb-3 mb-0" style='margin: 0in; font-size: 16px; font-family: "Angsana New", serif; text-align: center;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>(${teacher_dare ? `${teacher_dare.m_prefix ? teacher_dare.m_prefix.m_prefix_name : ''} ${teacher_dare.first_name ? teacher_dare.first_name : ''} ${teacher_dare.last_name ? teacher_dare.last_name : ''}` : `.....................................`})</span></p>
                        `

                }
            } else {
                form_teacher_dare += `<p style='font-weight: 700; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>อาจารย์ประจำชั้น / อาจารย์ผู้ควบคุม: .................................................</span></p>`
            }

            if (mapping_teaching_students && mapping_teaching_students.length > 0) {
                for (let index = 0; index < mapping_teaching_students.length; index++) {
                    const mapping_teaching_student = mapping_teaching_students[index];
                    const { student_dare } = mapping_teaching_student;
                    form_student_lists += `
                    <tr>
                        <td class="py-1" style="width: 10%; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0in 5.4pt; vertical-align: top;">
                            <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>${index + 1}</span></p>
                        </td>
                        <td class="py-1" style="width: 60%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                            <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>  ${student_dare && student_dare.m_prefix ? student_dare.m_prefix.m_prefix_name : ''} ${student_dare ? student_dare.first_name : ''} ${student_dare ? student_dare.last_name : ''}</span></p>  
                        </td>
                        <td class="py-1" style="width: 30%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                            <p style='text-align:center; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>${student_dare.id_card ? student_dare.id_card : ''}</span></p>        
                        </td>
                    </tr>
                    `
                }
            }


            let info_teaching_dare = `
                <p style='font-weight: 700; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>ประจำภาคเรียนที่: ${term ? term : '.....................'}/${fiscal_year ? fiscal_year : '.....................'}</span></p>
                <p style='font-weight: 700; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>โรงเรียน: ${m_school && m_school.m_school_name ? m_school.m_school_name : '.....................'}</span></p>
                <p style='font-weight: 700; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>ห้องเรียน: ${m_class && m_class.m_class_name ? `${m_class.m_class_name}/${room}` : '.....................'}</span></p>
                <p style='font-weight: 700; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>จำนวนนักเรียน: ${actual_student ? actual_student : '.....................'} คน</span></p>
                ${form_teacher_dare}
                <p style='font-weight: 700; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>ผู้อำนวยการโรงเรียน / ผู้บริหารโรงเรียน: ${director_dares && director_dares[0] ? `${director_dares[0].m_prefix ? director_dares[0].m_prefix.m_prefix_name : ''} ${director_dares[0].first_name} ${director_dares[0].last_name}` : '.................................................'}</span></p>
            `

            // let info_student_list = `
            //     <p style='font-weight: 700; margin:0in;font-size:18px;font-family:"Angsana New",serif;margin-top:12.0pt;text-align:center;'><span style="font-size: 18px;"><strong><span style='font-family:"TH Niramit AS";'>รายชื่อนักเรียน ชั้น ${m_class.m_class_name ? `${m_class.m_class_name}/${room}` : '.....................'}</span></strong></span></p>
            //     <br />
            //     <p style='font-weight: 700; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>โรงเรียน: ${m_school && m_school.m_school_name ? m_school.m_school_name : '.....................'}</span></p>
            //     <p style='font-weight: 700; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>ครูประจำชั้น: ${user_profile && user_profile.m_rank ? user_profile.m_rank.m_rank_name : ''} ${user_profile.first_name ? user_profile.first_name : ''} ${user_profile.last_name ? user_profile.last_name : ''}</span></p>
            //     <p style='font-weight: 700; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>รหัสประจำตัวครูตำรวจ D.A.R.E: ${user_profile && user_profile.id_governmen ? user_profile.id_governmen : '.....................'}</span></p>
            //     <p style='font-weight: 700; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-weight: 700;font-family: "TH Niramit AS"; font-size: 16px;'>จำนวนนักเรียน: ${actual_student ? actual_student : '.....................'} คน</span></p>
            // `
            let sort_teaching_plans = sortBy(teaching_plans, [function (o) { return parseInt(o.lesson_number); }])

            if (sort_teaching_plans && sort_teaching_plans.length > 0) {
                for (let index = 0; index < sort_teaching_plans.length; index++) {
                    const lesson = sort_teaching_plans[index];
                    let form_lesson_content = '';
                    let form_lesson_note = '';
                    let form_lesson_teaching_actual_imgs = `
                        <p class="pb-4" style='margin:0in;font-size:18px;font-family:"Angsana New",serif;margin-top:12.0pt;text-align:center;'><span style="font-size: 18px;"><strong><span style='font-family:"TH Niramit AS";'>ภาพกิจกรรม</span></strong></span></p>
                        <p class="pb-3" style="text-align: center;"><span style="font-size: 18px;"><strong><span style='font-family: "TH Niramit AS";'>ครั้งที่ &nbsp;${lesson.lesson_number ? lesson.lesson_number : ''} &nbsp; เรื่อง &nbsp; บทที่ ${lesson.lesson_number ? lesson.lesson_number : ''} &nbsp; ${lesson.lesson_name ? lesson.lesson_name : ''}</span></strong></span></p>
                        `;
                    let lesson_contents = lesson.content ? lesson.content.split('\n') : '';
                    let teaching_actual_imgs = lesson.teaching_actual_imgs ? lesson.teaching_actual_imgs : null;
                    if (teaching_actual_imgs && teaching_actual_imgs.length > 0) {
                        for (let index = 0; index < teaching_actual_imgs.length; index++) {
                            const teaching_actual_img = teaching_actual_imgs[index];
                            const { teaching_act } = teaching_actual_img;
                            if (teaching_act) {
                                const toDataURL = (url) =>
                                    fetch(url)
                                        .then((response) => response.blob())
                                        .then(
                                            (blob) =>
                                                new Promise((resolve, reject) => {
                                                    const reader = new FileReader();
                                                    reader.onloadend = () => resolve(reader.result);
                                                    reader.onerror = reject;
                                                    reader.readAsDataURL(blob);
                                                })
                                        );
                                let imgBase64 = await toDataURL(teaching_act);
                                if (imgBase64) {
                                    form_lesson_teaching_actual_imgs += `<p class="py-3" style="text-align: center;"><img src=${imgBase64 ? imgBase64 : ''} style="max-height: 340px;"/></p>`
                                }
                            }
                            if (teaching_actual_img.teaching_actual_img) {
                                const toDataURL = (url) =>
                                    fetch(url)
                                        .then((response) => response.blob())
                                        .then(
                                            (blob) =>
                                                new Promise((resolve, reject) => {
                                                    const reader = new FileReader();
                                                    reader.onloadend = () => resolve(reader.result);
                                                    reader.onerror = reject;
                                                    reader.readAsDataURL(blob);
                                                })
                                        );
                                let imgBase64 = await toDataURL(teaching_actual_img.teaching_actual_img);
                                if (imgBase64) {
                                    form_lesson_teaching_actual_imgs += `<p class="py-4" style="text-align: center;"><img src=${imgBase64 ? imgBase64 : ''} style="max-height: 340px;"/></p>`
                                }
                            }

                            // if (teaching_actual_imgs.length == 1) {
                            //     form_lesson_teaching_actual_imgs += `
                            //         <p class="py-5" style="text-align: center; width: 480px; height: 400px;"></p>
                            //     `
                            // }
                        }
                    }
                    // else {
                    //     form_lesson_teaching_actual_imgs += `
                    //         <p class="py-4" style="text-align: center; width: 480px; height: 400px;"></p> 
                    //         <p class="py-4" style="text-align: center; width: 480px; height: 400px;"></p> 
                    //     `
                    // }


                    for (let index = 0; index < lesson_contents.length; index++) {
                        const lesson_content = lesson_contents[index];

                        const size = 39
                        if (lesson_content.length > size) {
                            const numChunks = Math.ceil(lesson_content.length / size)
                            let chunk = ''
                            for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
                                chunk = lesson_content.substr(o, size)

                                form_lesson_content += `<p style='margin:0in;font-size:15px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 15px;'>${chunk}</p>`
                            }
                        } else {
                            form_lesson_content += `<p style='margin:0in;font-size:15px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 15px;'>${lesson_content}</p>`
                        }
                    }

                    const lesson_note = lesson.note ? lesson.note.replace(/ /g, '') : '';
                    const size = 11;
                    if (lesson_note.length > size) {
                        const numChunks = Math.ceil(lesson_note.length / size)
                        let chunk = ''
                        for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
                            chunk = lesson_note.substr(o, size)

                            form_lesson_note += `<p style='margin:0in;font-size:15px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 15px;'>${chunk}</p>`
                        }
                    } else {
                        form_lesson_note += `<p style='margin:0in;font-size:15px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 15px;'>${lesson_note}</p>`
                    }


                    form_lessons_table += `
                        <tr>
                            <td style="width: 4%;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>${lesson.lesson_number ? lesson.lesson_number : ''}</span></p>
                            </td>
                            <td style="width: 18%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>${lesson.date ? formatThai(lesson.date) : ''}</span></p>
                            </td>
                            <td style="width: 20%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>${lesson.time ? `${lesson.time} น.` : ''}</span></p>
                            </td>
                            <td style="width: 58%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>บทที่ ${lesson.lesson_number ? lesson.lesson_number : ''} &nbsp;${lesson.lesson_name ? lesson.lesson_name : ''}</span></p>
                            </td>
                        </tr>
                    `

                    form_teaching_plan += `
                    <div style='max-height: 900.24px; height: 900.24px; width: 604px;' class="p-0 m-0">
                        <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 18px;"><strong><span style='font-family:"TH Niramit AS";'>บันทึกการสอน</span></strong></span></p>
                        <p class="pb-4" style='margin:0in;font-size:18px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 18px;"><strong><span style='font-family:"TH Niramit AS";'>โครงการการศึกษาเพื่อต่อต้านการใช้ยาเสพติดในเด็กนักเรียน (</span></strong><strong><span style='font-family:"TH Niramit AS";'>D.A.R.E.ประเทศไทย)</span></strong></span></p>
                        <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;margin-top:12.0pt;'><span style="font-size: 18px;"><strong><span style='font-family:"TH Niramit AS";'>ครั้งที่ &nbsp;${lesson.lesson_number ? lesson.lesson_number : ''} &nbsp; เรื่อง &nbsp; บทที่ ${lesson.lesson_number ? lesson.lesson_number : ''} &nbsp; ${lesson.lesson_name ? lesson.lesson_name : ''}</span></strong></p>
                        ${info_teaching_dare}
                        <br />
                        <table style="width:600px;border-collapse:collapse;border:none;">
                            <tbody>
                                <tr>
                                    <td style="width: 14%; border: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                                        <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;text-align:center;'><b style='font-family: "TH Niramit AS"; font-size: 16px;'>วัน เดือน ปี</b></p>
                                    </td>
                                    <td style="width: 16%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                                        <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;text-align:center;'><b style='font-family: "TH Niramit AS"; font-size: 16px;'>เวลา</b></p>
                                    </td>
                                    <td style="width: 53%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                                        <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;text-align:center;'><b style='font-family: "TH Niramit AS"; font-size: 16px;'>เนื้อหา / และกิจกรรมที่ทำการสอน</b></p>
                                    </td>
                                    <td style="width: 17%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                                        <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;text-align:center;'><b style='font-family: "TH Niramit AS"; font-size: 16px;'>หมายเหตุ</b></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 14%; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0in 5.4pt; vertical-align: top;">
                                        <p style='margin:0in;font-size:15px;font-family:"Angsana New",serif;text-align:center;'><span style='font-family: "TH Niramit AS"; font-size: 15px;'>${lesson.date ? formatThai(lesson.date) : ''}</span></p>
                                    </td>
                                    <td style="width: 18%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                                        <p style='margin:0in;font-size:15px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 15px;'>${lesson.time ? `${lesson.time} น.` : ''}</span></p>
                                    </td>
                                    <td style="width: 51%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                                    <p style='margin:0in;font-size:15px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 15px;'>บทที่ ${lesson.lesson_number ? lesson.lesson_number : ''} &nbsp; ${lesson.lesson_name ? lesson.lesson_name : ''}</span></p>
                                        ${form_lesson_content}
                                    </td>
                                    <td style="width: 17%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                                        ${form_lesson_note}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p style='margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>&nbsp;</span></p>
                        <p style='font-weight: 700; margin:0in;font-size:16px;font-family:"Angsana New",serif;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>ความเห็นอาจารย์ประจำชั้น หรือ อาจารย์ผู้ดูแลการเรียนการสอน</span></p>
                        <p style='font-weight: 700; margin:0in;font-size:16px;font-family:"Angsana New",serif;margin-top:6.0pt;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>...................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................</span></p>
                        <p style='margin: 0in; font-size: 16px; font-family: "Angsana New", serif; text-align: center;'><span style='font-family: "TH Niramit AS"; font-size: 16px;'>&nbsp;</span></p>
                        ${signature_teacher_dare}
                        <p class="pb-0 mb-0" style='font-weight: 700; margin: 0in; font-size: 16px; font-family: "Angsana New", serif; text-align: center;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>ลงชื่อ...........................................................................ผู้ดำเนินโครงการ</span></p>
                        <p class="pb-0 mb-0" style='font-weight: 700; margin: 0in; font-size: 16px; font-family: "Angsana New", serif; text-align: center;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>(${user_profile ? `${user_profile && user_profile.m_rank ? user_profile.m_rank.m_rank_name : ''} ${user_profile.first_name ? user_profile.first_name : ''} ${user_profile.last_name ? user_profile.last_name : ''}` : `.....................................`})</span></p>
                        <p style='font-weight: 700; margin: 0in; font-size: 16px; font-family: "Angsana New", serif; text-align: center;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>ตำแหน่ง ${user_profile.position_name ? user_profile.position_name : '.................................'}</span></p>
                    </div>

                    <div style='max-height: 900.24px; height: 900.24px; width: 604px;' class="p-0 m-0">
                        ${form_lesson_teaching_actual_imgs}
                    </div>
                    `
                }
            }

            f_html_student_list += `
            <div>
                <p style='font-weight: 700; margin:0in;font-size:18px;font-family:"Angsana New",serif;margin-top:12.0pt;text-align:center;'><span style="font-size: 18px;"><strong><span style='font-family:"TH Niramit AS";'>รายชื่อนักเรียน ชั้น ${m_class.m_class_name ? `${m_class.m_class_name}/${room}` : '.....................'}</span></strong></span></p>
                <br />
                ${info_teaching_dare}
                <br />
                <table style="width:600px;border-collapse:collapse;border:none;">
                    <tbody>
                    <tr>
                        <td style="width: 10%; border: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                            <p class="py-1" style='margin:0in;font-size:16px;font-family:"Angsana New",serif;text-align:center;'><b style='font-family: "TH Niramit AS"; font-size: 16px;'>ลำดับ</b></p>
                        </td>
                        <td style="width: 60%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                            <p class="py-1" style='margin:0in;font-size:16px;font-family:"Angsana New",serif;text-align:center;'><b style='font-family: "TH Niramit AS"; font-size: 16px;'>ชื่อ นามสกุล นักเรียน</b></p>
                        </td>
                        <td style="width: 30%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                            <p class="py-1" style='margin:0in;font-size:16px;font-family:"Angsana New",serif;text-align:center;'><b style='font-family: "TH Niramit AS"; font-size: 16px;'>เลขประจำตัวประชาชน</b></p>
                        </td>
                    </tr>
                        ${form_student_lists}
                    </tbody>
                </table>
                <br />
                <br />
                <p style='font-weight: 700; margin: 0in; font-size: 16px; font-family: "Angsana New", serif; text-align: center;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>ตรวจแล้วถูกต้อง</span></p>
                <br />
                <br />
                <p style='font-weight: 700; margin: 0in; font-size: 16px; font-family: "Angsana New", serif; text-align: center;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>ลงชื่อ..................................................................</span></p>
                <p style='font-weight: 700; margin: 0in; font-size: 16px; font-family: "Angsana New", serif; text-align: center;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>( ${user_profile.m_rank ? user_profile.m_rank.m_rank_name : ''} ${user_profile.first_name ? user_profile.first_name : '.........................'} ${user_profile.last_name ? user_profile.last_name : '.........................'} )</span></p>
                <p style='font-weight: 700; margin: 0in; font-size: 16px; font-family: "Angsana New", serif; text-align: center;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>ตำแหน่ง ${user_profile.position_name ? user_profile.position_name : '.................................'}</span></p>
            </div>
            `


            f_html += `
                <div style='max-height: 900.24px; height: 900.24px; width: 604px;' class="p-0 m-0">
                    <p style='margin:0in;font-size:18px;font-family:"Angsana New",serif;'><strong><span style='font-size: 18px; font-family: "TH Niramit AS";'>แผนการสอน โครงการการศึกษาเพื่อต่อต้านการใช้ยาเสพติดในเด็กนักเรียน (</span></strong><span style="font-size: 18px;"><strong><span style='font-family: "TH Niramit AS";'>D.A.R.E.ประเทศไทย)</span></strong></span></p> 
                    <br />
                    ${info_teaching_dare}
                    <br />
                    <table style="width:600px; border-collapse:collapse;border:none;">
                        <tbody>
                            <tr>
                                <td style="width: 4%;border: 1pt solid windowtext;padding: 0in 5.4pt;vertical-align: top;">
                                    <p class="py-1" style='margin:0in;font-size:16px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 16px;"><strong><span style='font-family: "TH Niramit AS";'>ลำดับ</span></strong></span></p>
                                </td>
                                <td style="width: 18%;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;padding: 0in 5.4pt;vertical-align: top;">
                                    <p class="py-1" style='margin:0in;font-size:16px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 16px;"><strong><span style='font-family:"TH Niramit AS";'>วัน เดือน ปี</span></strong></span></p>
                                </td>
                                <td style="width: 18%;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;padding: 0in 5.4pt;vertical-align: top;">
                                    <p class="py-1" style='margin:0in;font-size:16px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 16px;"><strong><span style='font-family:"TH Niramit AS";'>เวลา</span></strong></span></p>
                                </td>
                                <td style="width: 60%;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;padding: 0in 5.4pt;vertical-align: top;">
                                    <p class="py-1" style='margin:0in;font-size:16px;font-family:"Angsana New",serif;text-align:center;'><span style="font-size: 16px;"><strong><span style='font-family:"TH Niramit AS";'>เนื้อหาที่ทำการสอน</span></strong></span></p>
                                </td>
                            </tr>
                            ${form_lessons_table}
                        </tbody>
                    </table>
                    <br />
                    <br />
                    ${signature_teacher_dare}
                    <p class="pb-0 mb-0" style='font-weight: 700; margin: 0in; font-size: 16px; font-family: "Angsana New", serif; text-align: center;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>ลงชื่อ...........................................................................ผู้ดำเนินโครงการ</span></p>
                    <p class="pb-0 mb-0" style='font-weight: 700; margin: 0in; font-size: 16px; font-family: "Angsana New", serif; text-align: center;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>(${user_profile ? `${user_profile && user_profile.m_rank ? user_profile.m_rank.m_rank_name : ''} ${user_profile.first_name ? user_profile.first_name : ''} ${user_profile.last_name ? user_profile.last_name : ''}` : `.....................................`})</span></p>
                    <p style='font-weight: 700; margin: 0in; font-size: 16px; font-family: "Angsana New", serif; text-align: center;'><span style='font-weight: 700; font-family: "TH Niramit AS"; font-size: 16px;'>ตำแหน่ง ${user_profile.position_name ? user_profile.position_name : '.................................'}</span></p>
                </div>
                
                ${form_teaching_plan}
                
                ${f_html_student_list}
                
            `
        }
    }

    return `<!DOCTYPE html>
    <html>
    
    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title></title>
    </head>
    
    <body style="color: rgb(0, 0, 0); background-color: rgb(255, 255, 255);">
        ${f_html}
    </body>
    
    </html>`
}