<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="enableDownload"
      :preview-modal="previewModal"
      :paginate-elements-by-height="1122.24"
      :pdf-quality="2"
      :manual-pagination="false"
      :pdf-format="typeFormat"
      :pdf-orientation="orientation"
      pdf-content-width="624px"
      ref="html2Pdf"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      :html-to-pdf-options="htmlToPdfOptions"
    >
      <section slot="pdf-content" v-html="format"></section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  props: {
    item: Object,
    format: String,
    orientation: {
      type: String,
      default: "portrait",
    },
    typeFormat: {
      type: String,
      default: "a4",
    },
    enableDownload: {
      type: Boolean,
      default: false,
    },
    previewModal: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {};
  },

  components: {
    VueHtml2pdf,
  },

  computed: {
    htmlToPdfOptions() {
      return {
        filename: `${this.item.number}`,
        // margin: [20.892, 20.066],
        margin: [25.4, 25.4],
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        jsPDF: {
          format: "a4",
          orientation: "portrait",
        },
        html2canvas: {
          useCORS: true,
        },
      };
    },
  },

  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    onProgress(progress) {
      if (progress === 100) {
        this.$emit("onProgressDone");
      }
    },
    hasStartedGeneration() {},
    hasGenerated() {},
  },
};
</script>